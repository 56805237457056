<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Favourite Plans
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Favourite</a></li>
          <li class="breadcrumb-item active" aria-current="page">Plans</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterFavourites" class="forms-sample">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterFavourites" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider action="#">
                      <b-form-group class="form-group d-lg-flex" label="Per Page">
                        <b-form-input type="number" class="form-control" placeholder="perPage" v-model="paginate"></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">{{ list_title }}</h4>
              <download-excel
                class="btn btn-success"
                :data="favourites"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="favorite_plans.xlsx">
                Download Plans
              </download-excel>
            </div>
            <b-table :items="favourites" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <span>
                  <router-link :to="{name: 'customer-view', params: {id: data.item.user_info ? data.item.user_info.id : null}}"
                               target="_blank">{{data.item.user_info ? `${data.item.user_info.first_name} ${data.item.user_info.last_name}` : ''}}
                  </router-link>
                </span>
              </template>
              <template v-slot:cell(phone)="data">
                <span>
                  {{ data.item.user_info ? data.item.user_info.phone : '' }}
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="paginate"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue"
import { ValidationObserver } from 'vee-validate'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import _ from 'lodash'

import helper from '../../util/helper.js'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      date_range: "",
      start_date: "",
      end_date: "",
      list_title: "Plans",
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'comment', sortable: true },
        { key: 'city', sortable: true },
        { key: 'name', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      json_fields: {
        'ID': 'id',
        'city': 'city',
        'city id': 'city_id',
        'comment': 'comment',
        'Created': 'created_at',
        "Plan Number": "planNo",
        'District': 'district',
        'Plot Count': 'plots_count',
        'City': 'city.en_name',
        'User First Name': 'user_info.first_name',
        'User Last Name': 'user_info.last_name',
        'User Phone': 'user_info.phone',
        'User Id': 'user_info.id',
        'User email': 'user_info.email',
      }
    };
  },
  computed: {
    rows() {
      return this.$store.state.plan.favourite_plan_meta.total
    },
    paginationPageSize() {
      return this.$store.state.plan.favourite_plan_meta.paginate
    },
    favourites() {
      return this.$store.state.plan.favourite_plans
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.plan.favourite_plan_meta.current_page)
        this.fetchFavourites(this.currentPage)
    },
    date_range(newVal) {
      if(newVal[0] != null && newVal[1] != null) {
        this.start_date = newVal[0]
        this.end_date = newVal[1]
      }
      else {
        this.start_date = ""
        this.end_date = ""
      }
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    filterFavourites: _.debounce(function() {
      this.fetchFavourites(this.currentPage)
    }, 2000),
    fetchFavourites(current_page = 1) {
      this.isBusy = true
      let payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          start_date: this.start_date,
          end_date: this.end_date
        },
      }
      
      if (payload.meta.start_date == "") delete payload.meta.start_date
      if (payload.meta.end_date == "") delete payload.meta.end_date

      this.$store.dispatch("plan/fetchPlanFavourites", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
  },
  created() {
    this.fetchFavourites()
 },
 components: {
   ValidationObserver,
   DatePicker
 }
}
</script>
